import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const GuideManchester1 = () => (
  <Layout>
    <SEO title="Manchester Sauna Guide - Hotels with Sauna" />
      <p>SINGLE-GENDER SAUNAS</p>
      <p>A few years ago, there were three hotels in Manchester city centre with single-gender changing room saunas. Now (November 2017) there are none.</p>
      <p>MIXED SAUNAS</p>
      <p>The Macdonald has the most extensive heat facility with a standard sauna, infra-red sauna, steam and ice room. Also some heated chairs. No pool.</p>
      <p>The Lowry, which had previously been notable for expressly stating that it was usual to go nude in their saunas, has had a couple of revamps. So far as I can tell there is now just one mixed sauna. Definitely no pool.</p>
      <p>The Midland Hotel now has a very expensive day rate to go with the fancy spa. There is a new sauna poolside, which is joined by some experience showers. The swimming pool and hot tub have not changed much except to add a waterfall shower. Membership is available.</p>
      <p>The Radisson Edwardian Blu sauna is practically poolside, in an area including a steam room and two open showers. You can use it in just a towel but you&#8217;ll be heading back to the changing room to shower. It is mostly used in swimwear. Day rate is £15 if you&#8217;re not staying or having a treatment. Membership is available.</p>
      <p>Park Inn by Radisson has a pool-side sauna.</p>
      <p>The Hilton has a pool-side sauna, a rare proper one where you can pour water on the coals. Also a steam room and hot tub.</p>
      <p>New-ish hotel Innside has a large sauna with glass doors that look onto a mixed shower. Although towels are freely available you are asked to take only one. Automatic. Also a steam room. No pool.</p>
      <p>Sacha&#8217;s has a small dingy sauna, a steam room, and a small pool. There&#8217;s a very powerful shower though. Facility is at extra charge to hotel guests. Membership available.</p>
      <p>The Malmaison has a large sauna and smaller saunarium in the downstairs spa. Towels available. No pool.</p>
      <p>The Novotel has a sauna which has been updated recently.</p>
      <p>OFF THE LIST</p>
      <p>The Thistle no longer has a gym at all. It was the last with male and female saunas, and had the cheapest day rate.</p>


    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default GuideManchester1
